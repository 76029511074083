import React from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";

export default ({ title, content, img, align, href, staticImg }) => {
  let items = (
    <>
      <div>
        <h4>{title}</h4>
        <div className={classes.content}>{content}</div>
      </div>
      {img ? <img src={img} alt={title} /> : null}
      {staticImg ? staticImg : null}
    </>
  );
  return href ? (
    <Link to={href} className={[classes.card, classes[align]].join(" ")}>
      {items}
    </Link>
  ) : (
    <div className={[classes.card, classes[align]].join(" ")}>{items}</div>
  );
};
