import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/compliant.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import ComplianceGrid from "components/services/ComplianceGrid";
import { Link } from "gatsby";

export default () => (
  <>
    <Meta url="/services/compliance/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Compliance"
      black="for China"
      text="Keep your business compliant with Chinese law with the required licenses, certifications, copyright certificates and other documents for your app or game."
    />
    <ImageBlock
      title="Following the law doesn't need to be difficult"
      text={
        <>
          <p>
            App and game publishers in China must adhere to a set of laws and
            regulations that can seem opaque and confusing to foreign developers
            and companies. AppInChina simplifies and demystifies this process
            for our clients.
          </p>
          <p>
            The Chinese government has outlined{" "}
            <Link to="/services/localization/compliance/">six obligations</Link>{" "}
            that app and game creators must follow, in addition to the following
            licensing requirements.
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/signature.jpg"
          alt="Alipay"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <ComplianceGrid />
    <CTA />
    <Footer />
  </>
);
