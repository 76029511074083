import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";

export default ({ title, img, text }) => (
  <Layout>
    <div className={classes.content}>
      <div className={classes.img}>{img}</div>
      <div className={classes.text}>
        {title ? <h2>{title}</h2> : null}
        {text}
      </div>
    </div>
  </Layout>
);
