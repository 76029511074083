import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import ads from "images/svg_icons/ad-block.svg";
import payment from "images/svg_icons/payment.svg";
import { Link } from "gatsby";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>Our Compliance Solutions</h3>
      <div className={classes.cards}>
        <Card
          title="App Compliance"
          align="verticalreverse"
          content={
            <>
              <p>
                Mobile apps or online apps in China require special
                certifications to be distributed on Chinese app stores (the only
                authorized way for distributing apps). These may include:
              </p>
              <ul>
                <li>
                  <Link to="/services/distribution/copyright-certificate/">
                    Software Copyright Certificate (SCC)
                  </Link>
                </li>
                <li>
                  <Link to="/how-can-i-get-an-icp-license-for-china/">
                    Standard Internet Content Provider (ICP) License
                  </Link>
                </li>
                <li>SP License (for SMS functionality)</li>
                <li>
                  <Link to="/b24-call-center-services-license-for-china/">
                    B24
                  </Link>
                  &nbsp;or B25 SP License (for social functionality)
                </li>
                <li>"Network Performance" ICP option (for video)</li>
                <li>
                  <Link
                    to={`/b23-store-and-forward-services-license-for-china`}
                  >
                    B23 Store-and-forward Services License (for email,
                    voicemail, voice messaging, or faxing)
                  </Link>
                </li>
              </ul>
              <p>
                AppInChina can analyze your app, determine which licenses are
                needed, and apply on your behalf. See further on this page for
                other licenses that may be required for apps in specialized
                categories, such as video conferencing and educational apps.
              </p>
            </>
          }
          img={payment}
        />
        <Card
          title="Game Compliance"
          align="verticalreverse"
          content={
            <>
              <p>
                Whether you have a mobile, console, or online game, most of the
                certifications and licenses you need to publish and distribute
                in China are the same, and will depend on whether your game is
                monetized:
              </p>
              <ul>
                <li>Chinese entity</li>
                <li>
                  <Link to="/services/distribution/copyright-certificate/">
                    Software Copyright Certificate (SCC)
                  </Link>
                </li>
                <li>
                  <Link to="/blog/how-to-publish-your-game-in-china/">
                    Game License ISBN
                  </Link>
                </li>
                <li>Operational Record</li>
                <li>
                  <Link to="/how-can-i-get-an-icp-license-for-china/">
                    Commercial ICP
                  </Link>
                </li>
              </ul>
              <p>
                Licensing your game for sale in China is a long, involved{" "}
                <Link to="/blog/how-to-publish-your-game-in-china/">
                  process
                </Link>{" "}
                (particularly for games with in-app payments) that can take at
                least 6 months. Let AppInChina get your game to market as
                smoothly as possible, without dead-ends and needless hassle.
              </p>
              <p>
                For more info about publishing your game in China, check out our{" "}
                <Link href={`/blog/how-to-publish-your-game-in-china/`}>
                  guide.
                </Link>{" "}
              </p>
            </>
          }
          img={ads}
        />
      </div>
    </Layout>
  </div>
);
